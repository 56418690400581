<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Image</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="weight-form">
          <v-file-input
            label="Image File"
            v-model="fields.url"
            :rules="rules"
            outlined
            accept="image/png, image/jpeg"
            placeholder="Pick an image"
            prepend-icon="mdi-image"
            :error="errors.hasOwnProperty('image')"
            :error-messages="errors['image']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="weight-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        url: null,
        type: null,
      },
      image: {},
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2MB!",
      ],

      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
    };
  },

  computed: {},

  methods: {
    openForm: function (type, image = null) {
      if (image !== null) {
        this.image = image;

        this.fields.url = image.url ? new File([image.url], image.url) : null;

        this.isEditing = true;
      }

      this.fields.type = type;
      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        litterId: this.$route.params.litterId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.imageId = this.image.id;
      }

      this.$store
        .dispatch("lhl/litters/saveImage", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.type = null;
      this.fields.url = null;
    },
  },
};
</script>
